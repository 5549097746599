<template>
  <v-btn text class="mx-1" :href="href">
    <span v-if="badge === null">{{ title }}</span>
    <v-badge v-else color="secondary" :content="badge">{{ title }}</v-badge>
  </v-btn>
</template>

<script>
export default {
  props: {
    title: String,
    badge: String,
    href: String,
  },
}
</script>
