<template>
  <div v-if="!disabled">
    <v-list-group v-if="items">
      <template v-slot:activator>
        <NavDrawerItem v-bind="$props" />
      </template>
      <NavDrawerItemNested v-for="subitem in items" :key="subitem.title" v-bind="subitem" />
    </v-list-group>

    <NavDrawerItem v-else v-bind="$props" nested />
  </div>
</template>

<script>
import NavDrawerItem from '@/components/NavDrawerItem'

export default {
  name: 'NavDrawerItemNested', // Naming component necessary in order for component to be used recursively
  components: {
    NavDrawerItem,
  },
  props: {
    items: Array,
    badge: String,
    title: String,
    href: String,
    disabled: Boolean,
    category: String,
  },
}
</script>
