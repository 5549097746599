<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-btn text class="mx-1" v-on="on" @click="fetchItems">
        <span v-if="badge === null">{{ title }}</span>
        <v-badge v-else color="secondary" :content="badge">{{ title }}</v-badge>
      </v-btn>
    </template>

    <v-list>
      <template v-if="items.length > 0">
        <div v-for="item in items" :key="item.title">
          <AppBarMenuNested v-if="item.items" v-bind="item" />
          <AppBarItemNested v-else :key="item.title" v-bind="item" />
        </div>
      </template>
      <v-progress-circular v-else indeterminate color="secondary" :size="40" :width="4" class="ma-5" />
    </v-list>
  </v-menu>
</template>

<script>
import AppBarMenuNested from '@/components/AppBarMenuNested'
import AppBarItemNested from '@/components/AppBarItemNested'

export default {
  components: {
    AppBarMenuNested,
    AppBarItemNested,
  },
  props: {
    title: String,
    badge: String,
    url: String,
  },
  data() {
    return {
      items: [],
    }
  },
  methods: {
    async fetchItems() {
      this.items = []
      const timestamp = Date.now()
      const cacheBuster = `?t=${timestamp}`
      const url = `${this.url}${cacheBuster}`
      const response = await this.$http.get(url)
      this.items = response.data
    },
  },
}
</script>
