<template>
  <v-list-item two-line :href="href" :disabled="disabled">
    <AppBarItemNestedContent v-bind="$props" />
  </v-list-item>
</template>

<script>
import AppBarItemNestedContent from '@/components/AppBarItemNestedContent'

export default {
  components: {
    AppBarItemNestedContent,
  },
  props: {
    title: String,
    badge: String,
    description: String,
    href: String,
    disabled: Boolean,
  },
}
</script>
