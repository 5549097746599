<template>
  <v-menu v-if="items" offset-y offset-x>
    <template v-slot:activator="{ on }">
      <v-list-item two-line :disabled="disabled" v-on="on">
        <AppBarItemNestedContent v-bind="$props" />
      </v-list-item>
    </template>
    <v-list>
      <AppBarMenuNested v-for="item in items" :key="item.title" v-bind="item" />
    </v-list>
  </v-menu>
  <AppBarItemNested v-else :key="title" v-bind="$props" />
</template>

<script>
import AppBarItemNestedContent from '@/components/AppBarItemNestedContent'
import AppBarMenuNested from '@/components/AppBarMenuNested'
import AppBarItemNested from '@/components/AppBarItemNested'

export default {
  name: 'AppBarMenuNested', // Naming component necessary in order for component to be used recursively
  components: {
    AppBarItemNestedContent,
    AppBarMenuNested,
    AppBarItemNested,
  },
  props: {
    items: Array,
    title: String,
    badge: String,
    description: String,
    disabled: Boolean,
    href: String,
  },
}
</script>
